// import React from "react";
// import { Link } from "react-router-dom";
// import Mens from "../images/Mens.jpg";
// import Womens from "../images/Womens.JPG";
// import { Box } from "@mui/material";

// function LandingPage() {
//   return (
//     <>
//       <Box border="2px solid black" height="100%">
//         <Link to="/Mens">
//           <button className="ml-2 mt-60 bg-white-500 hover:bg-white-700 text-white  py-1 px-4 rounded ">
//             <img src={Mens} alt="Mens" width="16%" />
//             Men's Wear
//           </button>
//         </Link>

//         <Link to="/Women" className="ml-  mt-3">
//           <button className="bg-white-500 hover:bg-white-700 text-white ">
//           <img src={Womens} alt="Womens" width="16%" />
//             Women's Wear
//           </button>
//         </Link>

//         {/* <Link to="/Kidswear" className="ml-4 mt-3">
//           <button className="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-4 rounded">
//             Kid's Wear
//           </button>
//         </Link>
//         <Link to="/Accessories" className="ml-4 mt-3">
//           <button className="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-4 rounded">
//             Home & Accessories
//           </button>
//         </Link> */}
//       </Box>
//     </>
//   );
// }

// export default LandingPage;

// <------------------------------------------------Working Code--------------------------------------------------->

// import React from 'react';
// import "../pages/LandingPage.css";
// import Mens from "../images/Mens.jpg";
// import Womens from "../images/Womens.jpg";
// import Kids from "../images/Kids.jpg";
// import Fabric from "../images/Fabric.jpg";
// import Home from "../images/Home.jpg";
// import { Link } from 'react-router-dom';
// import AnimatedLogo from "../components/AnimatedLogo/AnimatedLogo"

// const LandingPage = () => {
//   // Define your images and their fixed positions here
//   const images = [
//     { src: Mens, position: { left: '10%', top: '30%', width: '20%' }, url: '/mens' },
//     { src: Womens, position: { left: '33%', top: '60%', width: '20%', height: '60%' }, url: '/womens' },
//     { src: Kids, position: { left: '28%', top: '5%', width: '20%', height: '50%' }, url: '/kids' },
//     { src: Fabric, position: { left: '50%', top: '5%', width: '30%' }, url: '/fabric' },
//     { src: Home, position: { left: '58%', top: '75%', width: '30%' }, url: '/home' },

//   ];

//   return (
//     <div className="page-layout" style={{ display: 'flex', width: '100%', height: '500px' }}>
//     <div className="image-box" style={{ position: 'relative', width: '70%', height: '500px' }}>
//       {images.map((image, index) => (
//         <Link key={index} to={image.url} style={{ ...image.position, position: 'absolute' }}>
//         <img

//           src={image.src}
//           alt={`Navigate to ${image.url}`}

//           style={{ width: '100%', height: '100%', transition: 'transform 0.3s ease' }}
//             className="hover-zoom"

//         />
//         </Link>
//       ))}
//     </div>
//       <div className="lottie-section" style={{ width: '10%', height: '100%' }}>
//         <AnimatedLogo />
//       </div>
//     </div>
//   );
// };

// export default LandingPage;

// <-------------------------------------------------------------Trial--------------------------------------------------->

// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import "../pages/LandingPage.css";
// import Mens from "../images/Mens.jpg";
// import Womens from "../images/Womens.jpg";
// import Kids from "../images/Kids.jpg";
// import Fabric from "../images/Fabric.jpg";
// import Home from "../images/Home.jpg";
// import AnimatedLogo from "../components/AnimatedLogo/AnimatedLogo"; // Adjust the import path as necessary
// // import Searchbar from "./Searchbar";

// const LandingPage = () => {
//   const [showImages, setShowImages] = useState(false);

  // useEffect(() => {
  //   // Delay for the rest of the content to show after the logo animation
  //   setTimeout(() => {
  //     setShowImages(true);
  //   }, 3000); // Adjust based on the actual duration of your logo animation
  // }, []);

  // const images = [
  //   {
  //     src: Mens,
  //     label: "Men's Wear",
  //     position: {
  //       left: "7%",
  //       top: "30%",
  //       width: "20%",
  //       height: "auto",
  //       borderRadius: "15px",
  //     },
  //     url: "/Mens",
  //   },
  //   {
  //     src: Womens,
  //     label: "Women's Wear",
  //     position: {
  //       left: "30%",
  //       top: "45%",
  //       width: "20%",
  //       height: "45%",
  //       borderRadius: "15px",
  //     },
  //     url: "/Womens",
  //   },
  //   {
  //     src: Kids,
  //     label: "Kid's Wear",
  //     position: {
  //       left: "30%",
  //       top: "7%",
  //       width: "20%",
  //       height: "34%",
  //       borderRadius: "15px",
  //     },
  //     url: "/Kids",
  //   },
  //   {
  //     src: Fabric,
  //     label: "Fabric",
  //     position: {
  //       left: "53%",
  //       top: "3%",
  //       width: "35%",
  //       height: "47%",
  //       borderRadius: "15px",
  //     },
  //     url: "/Fabric",
  //   },
  //   {
  //     src: Home,
  //     label: "Home Accessories",
  //     position: {
  //       left: "53%",
  //       top: "53%",
  //       width: "40%",
  //       height: "auto",
  //       borderRadius: "15px",
  //     },
  //     url: "/Home",
  //   },
  // ];

//   return (
//     <div className="landing-page">
//       <div className="content-section">
//         {showImages &&
//           images.map((image, index) => (
//             <Link
//               key={index}
//               to={image.url}
//               style={{ position: "absolute", ...image.position }}
//             >
//               <img
//                 src={image.src}
//                 alt={`Navigate to ${image.url}`}
//                 style={{
//                   width: "100%",
//                   height: "100%",
//                   borderRadius: image.position.borderRadius,
//                 }}
//               />
//               <div className="image-label">{image.label}</div>
//             </Link>
//           ))}
//       </div>
//       <div className="logo-section">
//         <AnimatedLogo />
//       </div>
//     </div>
//   );
// };

// export default LandingPage;

// <------------------------------------------------------------------------------TEST---------------------------------------------------------------------------->

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../pages/LandingPage.css";
import Mens from "../images/Mens.jpg";
import Womens from "../images/Womens.jpg";
import Kids from "../images/Kids.jpg";
import Fabric from "../images/Fabric.jpg";
import Home from "../images/Home.jpg";
import AnimatedLogo from "../components/AnimatedLogo/AnimatedLogo"; // Adjust the import path as necessary
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const [showImages, setShowImages] = useState(false);
  const navigate = useNavigate(); // Initialize useHistory

  useEffect(() => {
    // Delay for the rest of the content to show after the logo animation
    setTimeout(() => {
      setShowImages(true);
    }, 1000); // Adjust based on the actual duration of your logo animation
  }, []);

  const images = [
    {
      src: Mens,
      label: "Men's Wear",
      position: {
        left: "7%",
        top: "30%",
        width: "20%",
        height: "auto",
        borderRadius: "15px",
      },
      category: "Mens",
    },
    {
      src: Womens,
      label: "Women's Wear",
      position: {
        left: "30%",
        top: "45%",
        width: "20%",
        height: "45%",
        borderRadius: "15px",
      },
      category: "Women",
    },
    {
      src: Kids,
      label: "Kid's Wear",
      position: {
        left: "30%",
        top: "7%",
        width: "20%",
        height: "34%",
        borderRadius: "15px",
      },
      category: "Kids",
    },
    {
      src: Fabric,
      label: "Fabric",
      position: {
        left: "53%",
        top: "3%",
        width: "35%",
        height: "47%",
        borderRadius: "15px",
      },
      category: "Fabric",
    },
    {
      src: Home,
      label: "Home Accessories",
      position: {
        left: "53%",
        top: "53%",
        width: "40%",
        height: "auto",
        borderRadius: "15px",
      },
      category: "Home",
    },
  ];

  const handleNavigate = (category) => {
    if ( category !== "Fabric" && category !== "Home"){
    navigate(`/category/${category}`); // Navigate to the category page
  }
  };

  return (
    <div className="landing-page">
      <div className="content-section">
        {showImages &&
          images.map((image, index) => (
            <div
              key={index}
              onClick={() => handleNavigate(image.category)}
              style={{ cursor: "pointer", position: "absolute", ...image.position }}
            >
              <img
                src={image.src}
                alt={`Navigate to ${image.label}`}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: image.position.borderRadius,
                }}
              />
              <div className="image-label">{image.label}</div>
            </div>
          ))}
      </div>
      <div className="logo-section">
        <AnimatedLogo />
      </div>
    </div>
  );
};

export default LandingPage